// BreakpointsContext.js
import React, { createContext, useContext } from 'react';
import { Grid } from 'antd';

const BreakpointsContext = createContext();

const BreakpointsProvider = ({ children }) => {
  const { sm, md, lg, xl } = Grid.useBreakpoint();
  return (
    <BreakpointsContext.Provider value={{sm: sm, md: md ,lg: lg, xl: xl}}>
      {children}
    </BreakpointsContext.Provider>
  );
};

const useBreakpoints = () => {
  return useContext(BreakpointsContext);
};

export { BreakpointsProvider, useBreakpoints };