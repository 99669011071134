import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import { AuthReducer } from "./redux/reducers/Auth";
import { UsersReducer } from "./redux/reducers/Users";
import { AnalyticReducer } from "./redux/reducers/analytics";
import { AnalyticGraphReducer } from "./redux/reducers/analyticsGraph";
import { UsersCoinsReducer } from "./redux/reducers/UserCoins";
import { totalCoinsReducer } from "./redux/reducers/totalCoins";
import { totalCasesReducer } from "./redux/reducers/reportCases";
import { AdManagerReducer } from "./redux/reducers/adManager";
import { UserManagementReducer } from "./redux/reducers/userManagement";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist'
import { LiveStreamsReducer } from "./redux/reducers/liveStream";
import { OpenAppAdReducer } from "./redux/reducers/openAppAd";


const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["auth"],
};

const middleware = [thunk];
const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const reducer = combineReducers({
  nav: changeState,
  auth: AuthReducer,
  getUsers: UsersReducer,
  getAnalytic: AnalyticReducer,
  getAnalyticsGraph: AnalyticGraphReducer,
  getUsersCoins: UsersCoinsReducer,
  getTotalCoins: totalCoinsReducer,
  getTotalCases: totalCasesReducer,
  getAds: AdManagerReducer,
  getManagementUsers: UserManagementReducer,
  getLiveStreams: LiveStreamsReducer,
  openAppAds: OpenAppAdReducer
});
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
