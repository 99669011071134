import { GET_OPEN_APP_ADS, OPEN_APP_ADS_LOADING } from "../constants/Constants";

let initialState = {
    openAppAdLoading: false,
    openAppAds: [],
  };
  
  export const OpenAppAdReducer = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_APP_ADS_LOADING:
        return { ...state, openAppAdLoading: action.payload };
  
      case GET_OPEN_APP_ADS:
        return { ...state, openAppAds: action.payload };
  
      default:
        return state;
    }
  };